import React, { useRef, useState, useEffect } from 'react';
import Highlighter from 'react-highlight-words';
import { Button, Input, Space, Table } from 'antd';
import { SearchOutlined, DownloadOutlined } from '@ant-design/icons';
import './rups.scss';
import AOS from 'aos';
import 'aos/dist/aos.css';
import axios from 'axios';
import { PuffLoader } from 'react-spinners';

const Rups = () => {
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    AOS.init({
      duration: 1300,
    });
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await axios.get('https://content.remala.id/wp-json/wp/v2/en-investor?order=desc&per_page=100');
        if (response.status === 200) {
          const rupsItems = response.data.filter((item) => item.acf && item.acf.type === 'rups').map((item, index) => ({ ...item.acf, key: index + 1 }));
          setData(rupsItems.filter((e) => e.status_posting === true));
        }
      } catch (error) {
        console.error('Terjadi kesalahan:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            Close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1677ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  // Menambahkan kolom nomor urut
  const columns = [
    {
      title: 'No',
      dataIndex: 'key',
      key: 'key',
      width: '5%',
      render: (text) => <span>{text}</span>,
    },
    {
      title: 'Name',
      dataIndex: 'judul',
      key: 'judul',
      ...getColumnSearchProps('judul'),
    },
    {
      title: 'Download',
      dataIndex: 'upload_file',
      key: 'upload_file',
      width: '10%',
      render: (text) => (
        <Button
          type="primary"
          icon={<DownloadOutlined />}
          size="small"
          onClick={() => {
            window.open(text, '_blank');
          }}
        >
          Download
        </Button>
      ),
    },
  ];

  return (
    <>
      <div className="mainContainerRups">
        {loading ? (
          <div className="loading-spinner-news">
            <PuffLoader color="#007bff" size={100} />
          </div>
        ) : (
          <>
            <div className="rupsTop" data-aos="fade-down">
              <h4>The company has made announcements regarding the Annual General Meeting of Shareholders (AGM) each year as follows:</h4>
              <br />
              <h2>GMS (General Meeting of Shareholders)</h2>
            </div>
            <hr />
            <div className="rupsBottom" data-aos="fade-left">
              <Table columns={columns} dataSource={data} pagination={{ pageSize: 5 }} />
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Rups;
